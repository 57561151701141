
import './App.scss';
import React,{ Component } from 'react';
import * as THREE from 'three';
import canvas from './components/canvas';
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import { RectAreaLightHelper }  from "three/examples/jsm/helpers/RectAreaLightHelper.js";
import  {ParticleSystem } from './Particles';
import { TimelineMax, TweenMax } from 'gsap';
import { Group, Vector3 } from 'three';
import {LinearSpline } from './Particles';
import DRACOLoader from './draco';
import {BASIC, PRO} from "./Select";

const LoadingManager = new THREE.LoadingManager();


const JAZZ_NOTE =  'jazz-popup.png';
const METAL_NOTE  = 'metal-popup.png'
const DIAL_HELPER = 'interaction-popup.png';
const CLOSE_HELPER  = 'close-metal.png';

const BLOOM_SCENE = 1;

function degs(degs) {
 return  THREE.MathUtils.degToRad(degs)
}

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      start : false,
      showRain : false,
      loaded: false,
      loadingPercentage : 0,
      musicNote : JAZZ_NOTE,
      showClose : false
    };
    this.panComplete = false;
    this.panStarted = false;
    this.animateClouds = false;
    this.firstInteraction = true;
    this.dialOver = false;
    this.settings = {
        cloudRate : this.isPro() ? 10 : 50
    };
  }
  componentDidMount() {
    TweenMax.set(this.loadingLogo, {scale:0, opacity: 0});
    LoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      this.setState({loadingPercentage : Math.ceil((100 / itemsTotal ) * itemsLoaded)})

      if(itemsLoaded === itemsTotal) {
          this.completeLoad();
        
      }
    }

    
    this.stage = canvas(this.container, this.props.type === PRO);
    this.modelLoader = new GLTFLoader(LoadingManager);

    let draco = new DRACOLoader();
    draco.setDecoderPath( '/libs/draco/' );
    this.modelLoader.setDRACOLoader(draco);
    
    
    this.textureLoader = new THREE.TextureLoader(LoadingManager);
    this.buildScene();
    this.rolling = false;
    // this.jazz.play();

  }

  isPro() {
    return this.props.type === PRO;
  }

  isBasic() {
    return this.props.type === BASIC;
  }


  completeLoad() {

    this.setState({
      loaded : true
    },() => {
      let tl = new TimelineMax();
      tl.to(this.loadingInfoEl, {scale: 1, opacity: 0})
          .to(this.loadingLogo, {scale:1, opacity: 1})
          .from('.start-button', {scale: 0.9, opacity : 0});

    });


    // this.start();
  }

  start() {

    let tl = new TimelineMax();
    tl.to(this.loadingScree, { opacity: 0, duration: 1})
    .add(() => {
      this.loadingScree.style.display = 'none';
    })

    this.jazz.play();
    setTimeout(() =>   this.jazzCredit(), 1000);
  
    if(this.panComplete === false && this.panStarted === false) {
      this.cameraPan();

    }
  }

  showActionHelper() {
    console.log('hello');
    this.setState({
      musicNote : DIAL_HELPER
    }, ()=> {
      let tl = new TimelineMax();
      tl.to('.music-note', { opacity: 1, duration : 1});
    });
  }


  addHell() {
    let text = this.textureLoader.load('/assets/textures/clouds.png');
    let geo  = new THREE.PlaneBufferGeometry(1000, 1000);
    let mat = new THREE.MeshStandardMaterial({ map :text , transparent : true, opacity: 0.5})
    this.hellClouds = new THREE.Mesh(geo, mat);
    this.hellClouds.rotation.set(degs(90), 0, 0);
    this.hellClouds.position.set(0, 200, 0);
    let small = new THREE.PlaneBufferGeometry(500, 500);
    let matSmall = new THREE.MeshStandardMaterial({ map : text, transparent : true, opacity: 0.5})
    this.hellSmall = new THREE.Mesh(small, matSmall);
    this.hellSmall.rotation.set(degs(90), 0, 0);
    this.hellSmall.position.set(0, 150, 0);


    this.stage.addRenderHook(() => {
      this.hellClouds.rotation.z = (this.hellClouds.rotation.z + 0.001);
      this.hellSmall.rotation.z = (this.hellSmall.rotation.z - 0.002);
    });

     this.hellLight = new THREE.PointLight(0xffffff, 100 ,100);
     this.hellLight.decay = 5;

     
     this.hellLight.position.set(0, 160, 0);


    this.stage.scene.add(this.hellLight);
    this.stage.scene.add(this.hellClouds);
    this.stage.scene.add(this.hellSmall);

    this.hellLight.visible = false;
    this.hellClouds.visible = false;
    this.hellSmall.visible = false;

  
  }

  random(min, max) {
   return Math.random()*(max-min+1)+min;
  }


  addClickSpot() {
    let geo = new THREE.BoxBufferGeometry(4, 4);
    let mat = new THREE.MeshBasicMaterial({color : 0xffffff});
    let mesh = new THREE.Mesh(geo, mat);
    this.stage.scene.add(mesh);
    mesh.position.set(-29,-1.2, 25.7);
    mesh.rotation.y =  degs(40);
    mesh.visible = false;
    window.clik = mesh;
let intersected = null;
    this.stage.addRenderHook(() => {
        let intersects = this.stage.raycaster.intersectObject(mesh);
        if(intersects.length > 0) {
          this.dialOver = true;
           intersected = intersects[ 0];
           if(this.panComplete === true) {
             document.body.style.cursor = 'pointer';
           }


        }else {
          if(intersected) {
            intersected = null;
            document.body.style.cursor = 'initial';
            this.dialOver = false;

          }

        }
    });

  }

  buildScene() {
    window.camera = this.stage.camera;
    this.addClickSpot();
    this.addHell();
      this.lights();
      if(this.isPro()) {
        this.background();

      }
      this.mug();
      this.box();
      this.table();
      if(this.isPro()){
        this.wall();
      }

      this.particles();
      this.explosionSetup();
      window.addEventListener('click', () => {
        if(this.panComplete === true && this.dialOver === true)  {
          this.interaction();
        }
      });
     
    }

    background() {
      this.modelLoader.load('/assets/models/light/scene.gltf', model => {
        this.stage.scene.add(model.scene);
        model.scene.scale.set(100,100,100);
        model.scene.rotation.set(0, degs(-10), 0);
        model.scene.position.set(-200, -100, -100);
      });
      this.modelLoader.load('/assets/models/mac_book/scene.gltf', model => {
        this.stage.scene.add(model.scene);
        model.scene.scale.set(1,1,1);
        model.scene.rotation.set(0, degs(30), 0);
        model.scene.position.set(-80, -5, -25);
      });
    }

    cameraPan() {
        TweenMax.from(this.stage.camera.position, {x: -110, duration: 10});
        this.panStarted = true;
          setTimeout(() => {
            this.panComplete = true;
          }, 10000)
    }

    addMetors() {
      return new Promise(r => {

      for(let i = 0; i < 40; i ++) {
        let size =  Math.random() / 4;

        let mat = new THREE.MeshStandardMaterial({ color : 0xfdf77b, transparent : true, opacity:  1});
        let geo = new THREE.SphereBufferGeometry(size, 10);
     
        let mesh= new THREE.Mesh(geo, mat);
        this.parts.push({
          p : mesh,
          life : 0,
          size: size,
          velocity :new THREE.Vector3(-1, (-0.2 * size), 20),
          maxLife : 40
        });
        mesh.position.set( this.random(2, -5),this.random(6, 5), this.random(25,15) )
        this.stage.scene.add(mesh);
      }
    });
    }

    addClouds() {
      let mat = new THREE.SpriteMaterial({ map :  this.textureLoader.load('/assets/textures/smoke.png'), transparent : true});

      return new Promise(r => {
        for(let i = 0; i < this.settings.cloudRate; i ++) {
          let size = Math.random() *50;
          mat.blending = THREE.NormalBlending;
          let geo = new THREE.Sprite(mat);
          geo.visible = false;
          geo.scale.set(size, size)
          geo.position.set( this.random(2, -5),this.random(10, 7), this.random(25,15)); 
  
          this.clouds.push({
            c : geo,
            life : 0,
            maxLife : 20,
            velocity :new THREE.Vector3(-1, 9, 20),

          })
          this.stage.scene.add(geo);
        }
      });
     
    }


    explosionSetup() {
     
      this.parts  = [];
      this.clouds = [];
      this.addClouds();

      let s = new LinearSpline((t,a,b) => {
        return a+ t * (b -a);
    });
    
    s.AddPoint(0.0, 0.0);
    s.AddPoint(0.5, 0.5);
    s.AddPoint(1.0, 1.0);

      this.stage.addRenderHook(() => {
          //   this.parts.forEach((p, index) => {
          //     let cert = p.velocity.clone();
            
          //       cert.y = (p.life - cert.y);
          //       const t =  1.0 - (p.life/ p.maxLife);
          //       p.p.material.opacity = s.Get(t);
             
          //       p.life = (p.life +1 );
          //       let dir = p.p.position.clone().sub(cert).normalize();
          //       p.p.position.add( dir.clone().multiplyScalar(p.size * 5));

          //       if(p.life > p.maxLife ) {
          //           this.stage.scene.remove(p.p);
          //         this.parts.splice(index, 1);
                  
          //       }
          

          // });


           

           });


          this.stage.addRenderHook(() => {
    if(this.animateClouds === true) {
      for(let index = 0; index < this.clouds.length; index ++) {
       let  c = this.clouds[index];
        let cert = c.velocity.clone();
        c.c.visible = true;
      const t =  1.0 - (c.life/ c.maxLife);
      c.c.material.opacity = s.Get(t);

      let dir = c.c.position.clone().sub(cert).normalize();

      c.life ++;
      c.c.position.add( dir.clone().multiplyScalar(2));

      if(c.life > c.maxLife ) {
          this.stage.scene.remove(c.c);
        this.clouds.splice(index, 1);
        
      }

      }
    }
 
  });

    }

    particles() {
      this.particleSystem = new ParticleSystem(this.stage.scene, this.stage.camera, 'smoke.png', 5);
      this.particleSystemD = new ParticleSystem(this.stage.scene, this.stage.camera, 'fire.png',0);
      let last = null;
      this.stage.addRenderHook(t  => {
          if(last === null) {
            last = t;
          }
          this.particleSystemD.step((t - last) * 0.001);
                  this.particleSystem.step((t - last) * 0.001);
        last = t;
      });

    }

    rotate(el) {

    }

    interaction() {
      
      
      if(this.rolling) {
          this.animateClouds = false;
          this.addClouds();

        this.redLabelLight.intensity = 0;
        let tl = new TimelineMax();
        this.setState({showRain : false});
          this.setState({showClose: false});
        tl.to(this.stage.camera.position, { x : -18.6, y: -0.18, z : 46, duration : 1})
        .to(this.stage.camera.rotation,  {x : 0,y : -0,z: 0, duration : 1}, 0).add(() => {
          TweenMax.to(this.dial.rotation, {duration : 1, x : degs(45), onComplete : () => {

        
    this.hellLight.visible = false;
    this.hellClouds.visible = false;
    this.hellSmall.visible = false;

            this.greenLabelLight.intensity = 4;
            this.stage.scene.background = new THREE.Color(0x000000);
            this.rolling = false;
            this.particleSystemD.rate = 0;
            this.particleSystem.rate = 5;
            this.point.visible = false;
            this.yellowPoint.visible = false;
  
            this.spotLight.visible = true;
            this.yellowPointAnim.pause();
            this.lightAnim.pause();
            this.jazz.play();
            this.fire.pause();
            this.metal.pause();
           
            
          }});
        });
     
        return;
      }
  
   
       this.greenLabelLight.intensity = 0;
      TweenMax.to('.music-note', { duration : 0.2, opacity: 0});
     TweenMax.to(this.dial.rotation, {duration : 1, x : degs(-45), onComplete :() => {


         this.redLabelLight.intensity = 4;



       let tl = new TimelineMax();
        tl.to(this.stage.camera.position, { x : -11.85, y: -2, z : 35.3, duration : 1})
        .to(this.stage.camera.rotation,  {x : 0.8,y : -0.5,z: 0.5, duration : 1}, 0).add(() => {
          if(this.firstInteraction === true) {

              this.setState({
                musicNote : METAL_NOTE,
              }, () => {
                let tm = new TimelineMax();
                tm.to('.music-note', { duration:  1, opacity: 1})
                tm.to('.music-note', {duration : 1, delay : 2, opacity: 0})
                    .add(() => this.setState({showClose: true}));
              })

          }else {
            this.setState({showClose: true});
          }
          if(this.jazz) {
            this.jazz.pause();
            this.metal.play();
            this.fire.play();
            this.explosion.play();
            if(this.isPro()) {
              setTimeout(() => this.setState({showRain : true}), 1000);
              this.rainVid.play();

            }

          }

          this.animateClouds = true;

    
          this.hellLight.visible = true;
          this.hellClouds.visible = true;
          this.hellSmall.visible = true;
    
            this.cameraShake();


            this.animateLevelUp();

          this.rolling = true;
          this.particleSystem.rate = 50;
          this.particleSystemD.rate = 200;
          this.spotLight.visible = false;

          this.point.visible = true;
          this.yellowPoint.visible = true;


          this.lightAnim =  new TimelineMax({ repeat : -1});
          this.lightAnim.to(this.point, { intensity :0.8, duration : 0.2}).to(this.point, {intensity : 1.0, duration : 0.2})
          .to(this.point, { intensity :0.8, duration : 0.1});
          this.yellowPointAnim = new TimelineMax({ repeat : -1});
          this.yellowPointAnim.to(this.yellowPoint, { intensity :0.2, duration : 0.1, delay : 0.5}).to(this.yellowPoint, {intensity : 0.2, duration : 0.1})
          .to(this.yellowPoint, { intensity :0.2, duration : 0.1});
          this.firstInteraction = false;

        });

     
     }});
    

    }

    cameraShake() {
     let tl = new TimelineMax();
      tl.to(this.stage.camera.position, {x:"+=1", y:"+=1", yoyo:true, repeat:40, duration : 0.05}, 0)
      .to(this.stage.camera.position,  {x:"-=1",y:"-=1", yoyo:true, repeat:40, duration : 0.05}, 0 )
    }

    wall() {
      let geo = new THREE.PlaneBufferGeometry(700, 300, 700, 300);
      let col = this.textureLoader.load('/assets/textures/concrete_color.jpg');
      // let dis = this.textureLoader.load('/assets/textures/concrete_displacement.jpg');
      // let rough = this.textureLoader.load('/assets/textures/concrete_roughness.jpg');
      // let norm = this.textureLoader.load('/assets/textures/concrete_normal.jpg');
      let mat = new THREE.MeshStandardMaterial({
         color : 0xFFFFFF,
          map:  col,
          //  displacementMap : dis,
          //  displacementScale:  3,
          //  roughnessMap : rough,
             roughness : 1,
              // normalMap : norm
            });


      let mesh =  new THREE.Mesh(geo, mat);
      this.stage.scene.add( mesh);
      mesh.position.set(0, 0 , -150);
      this.wallObj = mesh;
      /// decal
            this.decal();

               this.modelLoader.load('/assets/models/wall/untitled.gltf', model => {
                 let s = model.scene;
                 this.stage.scene.add(s);
                 s.rotation.x = degs(90);
                 s.rotation.z = degs(-90);
                 s.position.set(-300, 0, 0);
                 s.scale.set(30, 30, 30);

                  let worldGeo = new THREE.PlaneBufferGeometry(700, 700);
                  let worldMat = new THREE.MeshPhongMaterial({ map : this.textureLoader.load('/assets/textures/dmc.jpg')});
                  let world = new THREE.Mesh(worldGeo, worldMat);
                  world.rotation.set(0, degs(80), 0);
                  world.position.set(-600, 0, -200);
                  this.stage.scene.add(world);

               }
            );


    
    
      

    }

decal() {
  let geo = new THREE.PlaneBufferGeometry(188, 70, 188, 70);
  let text = this.textureLoader.load('/assets/textures/decal.png')
  // let dis = this.textureLoader.load('/assets/textures/concrete_displacement.jpg');
  let mat = new THREE.MeshStandardMaterial({
    color: 0xFFFFFF,
    map : text,
    // displacementMap : dis,
    // displacementScale : 3,
    transparent : true,
    metalness :0.01
  });

  let mesh =  new THREE.Mesh(geo, mat);
  mesh.position.set(0, 60, -148.5);
  this.stage.scene.add(mesh);

}

table() {

let dis = this.textureLoader.load('/assets/textures/wood_dis.jpg');


  let geo = new THREE.PlaneBufferGeometry(300, 100, 300, 100);
  let mat = new THREE.MeshStandardMaterial({ color : 0xFFFFFF, map: dis});
  
  let mesh = new THREE.Mesh(geo, mat);
  mesh.rotation.x = degs(-90);
  mesh.position.set(-0, -5.5, 0);
  mesh.receiveShadow = true;
  this.stage.scene.add(mesh);

}

createSmokeParticle(material) {
  let p = new THREE.Sprite( material);
  p.position.set(this.random(2, -5), 10,this.random(25,15));
  return p;
}

addParticles(particles, material) {

  if(particles.length >= 50) {
    return;
  }

  setTimeout(() => {
    for(let i = 0; i < 10; i ++) {
      let p = this.createSmokeParticle(material);
      particles.push({particle : p, time: 0});
      this.stage.scene.add(p);
  }
  this.addParticles(particles, material);

  }, 1000);
}

  

  lights() {
    let ambient = new THREE.AmbientLight(0x404040);
    ambient.intensity = 2;
    this.stage.scene.add( ambient );
    let spot = new THREE.SpotLight(0xFFFFFF, 0.2);
    this.spotLight =  spot;
    spot.position.set(40,20,30);
    spot.castShadow = true;
    spot.shadow.mapSize.width = (256 * 5);
    spot.shadow.mapSize.height = (256 * 5);
    this.stage.scene.add(spot);
//     const spotLightHelper = new THREE.SpotLightHelper( spot );
// this.stage.scene.add( spotLightHelper );
    this.point = new THREE.PointLight(0xb73e0e, 0.8, 100 );
    this.point.position.set(-1, 20, 20);
    this.point.castShadow = true;
    this.point.visible = false;
      this.yellowPoint = new THREE.PointLight(0xfbec38, 0.1, 70 );
      this.yellowPoint.position.set(-1, 20, 20);
      this.yellowPoint.visible = false;

      this.stage.scene.add(this.point);
      this.stage.scene.add(this.yellowPoint);
  

  }

 


  box() {



    let scene = new THREE.Scene();
    
    
    this.stage.scene.add(scene);


    this.modelLoader.load(`/assets/models/${this.isPro() ? 'box-pro' : 'box'}/box-text.gltf`, model => {

      scene.add( model.scene);
      model.scene.scale.set(6, 6,6);
      // model.scene.rotation.set(0, degs(-20), 0);
      // model.scene.position.set(-30, 1, 20);
    
     



    model.scene.traverse(child => {
      if(child.isMesh) {
        child.material.metalness = 0.2
        child.castShadow = true;
    
      }


      
   
     
    })

  });


  let labelGeo = new THREE.PlaneBufferGeometry(3, 0.9);
  let labelText = this.textureLoader.load('/assets/textures/standard.png');
  let labelMat = new THREE.MeshStandardMaterial({ map : labelText, roughness : 1});
  let labelMesh = new THREE.Mesh(labelGeo, labelMat);
  labelMesh.position.set(8.1, 2.45, 3.4)
  labelMesh.rotation.set(0, degs(90) ,0);
  scene.add(labelMesh);


  let awesomeLGeo = new THREE.PlaneBufferGeometry(3, 0.9);
  let awesomeLText = this.textureLoader.load('/assets/textures/awesome.png');
  let awesomeLMat = new THREE.MeshStandardMaterial({ map : awesomeLText, roughness : 1});
  let awesomeLMesh = new THREE.Mesh(awesomeLGeo, awesomeLMat);
  awesomeLMesh.position.set(8.1, 2.45, -3.35)
  awesomeLMesh.rotation.set(0, degs(90) ,0);
  scene.add(awesomeLMesh);
  
  let labelLight =  new THREE.RectAreaLight(0x32b618, 4, 4, 0.9);
  this.greenLabelLight = labelLight;
  labelLight.position.set(8.2, 2.45, 3.4);
  labelLight.rotation.set(0, degs(90) ,0);
  scene.add(labelLight);
  let aLabelLight =  new THREE.RectAreaLight(0xff0d0d, 0, 4, 0.9);
  this.redLabelLight = aLabelLight;
  aLabelLight.position.set(8.2, 2.45, -3.35);
  aLabelLight.rotation.set(0, degs(90) ,0);
  scene.add(aLabelLight);

  scene.position.set(-35, 0.7, 20);
  scene.rotation.set(0, degs(-45), 0);

  this.modelLoader.load('/assets/models/dial.gltf', model => {
   
    this.dial = model.scene;
    scene.add( model.scene);
    model.scene.scale.set(6, 6,6);
    model.scene.rotation.set(degs(45),0, 0);
    model.scene.position.set(8, -2, 0);
  
  model.scene.traverse(child => {
    if(child.isMesh) {
      child.material.metalness = 0.2
      child.castShadow = true;
    
    }
 
   
  })

});
  }


  animateLevelUp() {
    let tl = new TimelineMax();
    TweenMax.set('.level-up-screen', {display : 'flex'});
    tl.to('.level-up-screen-bg', {yPercent: -25, duration: 2})
    .to('.level-up-screen-bg', {yPercent: 0, duration: 2})
    .to('.level-up-logo', {scale : 1, duration: 2, rotate: 720}, 0)
    .to('.level-up-screen', {opacity: 1, diration : 0.2}, 0)
    .to('.level-up-screen', {opacity : 0, delay: 2})
    .add(() =>       this.stage.scene.background = new THREE.Color(0xff0d0d), 0.2)
    .add(() => {
      TweenMax.set('.level-up-screen', {display : 'none'});
      TweenMax.set('.level-up-logo', {scale :0, rotate: 0});

    });
    
  }


  mug() {

    const cubeRenderTarget = new THREE.WebGLCubeRenderTarget( 128, { format: THREE.RGBFormat, generateMipmaps: true, minFilter: THREE.LinearMipmapLinearFilter } );

// Create cube camera
const cubeCamera = new THREE.CubeCamera( 1, 100000, cubeRenderTarget );
this.stage.scene.add( cubeCamera );

    this.modelLoader.load('/assets/models/mug3.gltf', model => {

        this.stage.scene.add( model.scene);
        model.scene.scale.set(6, 6,6);
        model.scene.rotation.set(0, degs(-20), 0);
        model.scene.position.set(-1, -5, 20);
      
      model.scene.traverse(child => {
        
        if(child.isMesh) {
          child.castShadow = true;
          child.receiveShadow = false;
          // this.stage.addRenderHook(() => {
          //   model.visible = false;
          //   cubeCamera.position.copy(child.position);
          //   cubeCamera.update(this.stage.renderer, this.stage.scene);
          //   model.visible = true;
          // });
       
        }
        // if(child.material) {
        //   child.material.envMap = cubeCamera.renderTarget.texture
        //   console.log(child);
        // }
       
      })

    });
  }


  jazzCredit() {
    console.log('hello');
    this.setState({
      musicNote : JAZZ_NOTE
    }, ()=> {
      let tl = new TimelineMax();
      tl.to('.music-note', { opacity: 1, duration : 1})
      .to('.music-note', { opacity : 0, duration : 1, delay : 5})
      .add(() => {
        this.setState({
          musicNote : DIAL_HELPER
        }, () => {
          let tl = new TimelineMax();
          tl.to('.music-note', { opacity: 1, duration : 1});

        })
      })

    });
  }


  
  render() {
    return (
      <>
        <div className='lower-third'><img src={'/assets/textures/logo.png'} className='lower-third-logo' /></div>
        {
          this.state.showRain &&
          <div className="hell-overlay"></div>
        }
        <div className={`video-wrapper  ${this.state.showRain ? 'show' : ''}`} >
        <video ref={r => this.rainVid = r}  className={`video-overlay`} src="/assets/videos/rain.mp4" muted autoPlay loop />

        </div>

      <audio ref={r => this.fire = r} src="/assets/music/fire.mp3"></audio>
      <audio ref={r => this.explosion = r} src="/assets/music/explosion.mp3"></audio>
          <audio ref={r => this.jazz = r} src="/assets/music/jazz.mp3"></audio>
          <audio ref={r => this.metal = r} src="/assets/music/metal.mp3"></audio>

      <div ref={r => this.container = r} className="App">
  
      </div>

        <div className='level-up-screen'>
          <div className='level-up-screen-bg' style={{ background : 'url(/assets/textures/level_up_bg.jpg)'}}></div>
          <div className='level-up-screen-static' style={{ background : 'url(/assets/textures/static.gif)'}}></div>
          <img className='level-up-logo' src="/assets/textures/level-up-logo.png" />
        </div>

      <div
      ref={ r => this.loadingScree = r}
     className='loading-screen'>
        <div ref={r => this.loadingInfoEl = r} className="loading-info">
          <h1 className='loading-percentage'>Loading Complete: {this.state.loadingPercentage}%</h1>
          <svg className="loading-circle" viewBox="0 0 500 500">
            <circle fill="#ffffff" cx="250" cy="250" r="250" /> 
            <circle strokeWidth="10" stroke="#ffc82e" fill="none" cx="250" cy="250" r="220" /> 
          </svg>
        </div>
      

       <svg preserveAspectRatio="none" className="loading-bg" viewBox="0 0 1920 1080"><defs><style></style><clipPath id="clip-path"><rect class="cls-1" width="1920" height="1080"/></clipPath></defs><g class="cls-2"><g id="Layer_1" data-name="Layer 1"><rect class="cls-3" width="1920" height="1080"/><g class="cls-4"><g class="cls-5"><path class="cls-6" d="M960,1678.49a1114.79,1114.79,0,0,1-158.45-11.33L967.12,572.85c8.29,1.2-8.29,1.17,0,0l147.76,1094.81A1113.93,1113.93,0,0,1,960,1678.49Zm457.87-99.1L967.12,572.85c7.39-3.36-6.3,5.45,0,0l715.57,836.78A1105.61,1105.61,0,0,1,1417.87,1579.39Zm-919-1.49a1105.62,1105.62,0,0,1-264.24-170.59L967.12,572.85c6.29,5.47-7.38-3.39,0,0Zm1389.95-405.1L967.12,572.85c4.38-6.76-2.23,7.55,0,0L2020.48,886.3A1102.63,1102.63,0,0,1,1888.82,1172.8Zm-1859.57-3A1102.83,1102.83,0,0,1-101.49,882.86l1068.61-310c2.21,7.56-4.35-6.78,0,0ZM2065.68,576.07,967.12,572.85h0c0-8.66,2.29,7.79,0,0l1054-311.24a1106,1106,0,0,1,44.53,309.67C2065.69,572.87,2065.69,574.47,2065.68,576.07ZM967.12,572.85l-1112.81-1.22a1106,1106,0,0,1,44.88-311.18L967.12,572.85C964.84,580.63,967.13,564.2,967.12,572.85Zm0,0c-4.53-7,6.4,5.56,0,0l717.36-835.32A1108.13,1108.13,0,0,1,1890.1-25.2Zm0,0L30.53-26.18A1108.1,1108.1,0,0,1,236.41-263.25l730.71,836.1C960.72,578.4,971.66,565.81,967.12,572.85Zm0,0c-7.13-3.27,7.84,1.12,0,0L1117.3-521.71A1101.33,1101.33,0,0,1,1420-432.8Zm0,0L501.06-433.3a1101.32,1101.32,0,0,1,302.88-88.59L967.12,572.85C959.29,574,974.27,569.59,967.12,572.85Z"/></g></g></g></g></svg>
          <img ref={r => this.loadingLogo = r} className='loading-logo' src={'/assets/textures/logo.png'} />

        {
          this.state.loaded === true &&
          <div className="start-button" onClick={() => {
            if(this.state.loaded === true) {
              this.start();
            }

          }}>
            Start
          </div>
        }

      </div>


        <div className="music-note"><div className="music-note inner"><img src={`/assets/textures/${this.state.musicNote}`} alt=""/></div></div>
        {
          this.state.showClose &&
          <div onClick={() => this.interaction()} className="action-note"><div className="action-note-inner"><img src={`/assets/textures/${CLOSE_HELPER}`} alt=""/></div></div>

        }

      </>
    );
  
  }
}

export default App;
