import { render } from '@testing-library/react';
import * as THREE from 'three';
import { Camera } from 'three';
import {EffectComposer } from '../post/EffectComposer';
import { RenderPass } from '../post/RenderPass';
import { BokehPass } from '../post/BokehPass';
import {ShaderPass} from '../post/ShaderPass';


// import * as dat from 'dat.gui';
import { UnrealBloomPass } from '../post/UnrealBloomPass';

// const gui = new dat.GUI();
const BLOOM_SCENE = 1;

var OrbitControls = require('three-orbit-controls')(THREE)



function canvas(el, postprocessing = false) {
    let camera, scene, renderer, raycaster, mouse; 
    renderer =  new THREE.WebGLRenderer({ antialias : false});

    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize( window.innerWidth, window.innerHeight );
    
    
    camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 1, 10000 );
    raycaster = new THREE.Raycaster();
    mouse = new THREE.Vector2();

    camera.target = new THREE.Vector3( 0, 0, 0 );
    renderer.shadowMap.enabled = true;

    window.addEventListener( 'resize', onWindowResize, false );
    window.addEventListener('mousemove', onMouseMove, false);


    function onMouseMove( e)  {
      e.preventDefault();
      mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
      mouse.y = - (e.clientY / window.innerHeight) * 2  +1;
      window.canvasMouse = mouse;
      // console.log(mouse);

    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize( window.innerWidth, window.innerHeight );

    }




    scene = new THREE.Scene();
    // scene.add( new THREE.AxesHelper(500));
    el.appendChild(renderer.domElement);

    renderer.render(scene, camera);

    camera.position.set( -18.60, 0.18, 46 );
    
    // const controls = new OrbitControls( camera, renderer.domElement );
    // controls.update();

    const post = initPost(scene, camera, renderer, postprocessing);



    const effectController = {
      focus: 10.0,
      aperture: 5,
      maxblur: 0.01
    };

    
    //
    // let stats = document.createElement('div');
    // stats.className = 'stats';
    // document.body.appendChild(stats);


    const matChanger = function ( ) {

      post.bokeh.uniforms[ "focus" ].value = effectController.focus;
      post.bokeh.uniforms[ "aperture" ].value = effectController.aperture * 0.00001;
      post.bokeh.uniforms[ "maxblur" ].value = effectController.maxblur;
  
    };
  
    // gui.add( effectController, "focus", 10.0, 3000.0, 10 ).onChange( matChanger );
    // gui.add( effectController, "aperture", 0, 10, 0.1 ).onChange( matChanger );
    // gui.add( effectController, "maxblur", 0.0, 0.01, 0.001 ).onChange( matChanger );
    // gui.close();
    //

    // function updateStats() {
    //   stats.innerHTML = `<p>Polly Count: ${renderer.info.render.triangles}</p> <p>Textures in Mem${renderer.info.memory.textures}</p><p>GEO in mem: ${renderer.info.memory.geometries}</p>`;
    //
    //     setTimeout(() => updateStats(), 500);
    // }
    //
    // updateStats();

    matChanger();
    let renderHooks = [];
    function animate() {

      requestAnimationFrame( t => {
         animate();

         renderHooks.forEach(hook => {
           new Promise(() => {
            hook(t);
           })
         });
        //  controls.update();
         raycaster.setFromCamera(mouse, camera);
         renderer.render(scene, camera);
         post.composer.render(0.1);

      } );


    }
    animate();

    return {
      addRenderHook: hook => {
        renderHooks.push(hook);
      },
  
      scene,
      renderer,
      camera,
      mouse,
      raycaster,
      initPost
    
    }

}

function initPost(scene, camera, renderer, all = false) {
  const renderPass = new RenderPass(scene, camera);



  const bokehPass = new BokehPass(scene, camera, {
      focus : 10,
      apreture : 5,
      maxblur : 0.01,
      width : window.innerWidth, 
      height: window.innerHeight
  });

  const composer = new EffectComposer( renderer);

  composer.addPass( renderPass);

  if(all) {
      const bloomPass = new UnrealBloomPass(new THREE.Vector2(window.innerWidth, window.innerHeight), 1.5,0.4, 0.85);



      const bloomLayer = new THREE.Layers();
      bloomLayer.set(BLOOM_SCENE);

      composer.addPass( bloomPass);
      composer.addPass( bokehPass);

  }



  
  return {
    composer,
    bokeh: bokehPass
  }

}

export default canvas;
