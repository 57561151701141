import React, {Component} from 'react';
import App from "./App";
import Landing from "./landing";

export const BASIC = 'basic';
export const PRO = 'pro';

class Select extends Component {


    constructor(props) {
        super(props);
        this.state = {
            type : null
        };
    }




    render() {
        return (
            <>
                <div className="mobile-screen">
                    Sorry! awesome coffee doesn't come in this screen size.
                </div>
                {
                    this.state.type === null &&
                      <Landing onClick={type => this.setState({ type : type})} />
                }
                {
                    this.state.type !== null &&
                        <App type={this.state.type} />
                }

            </>
        );
    }
}

export default Select;