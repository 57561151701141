import React, {Component} from 'react';
import {BASIC, PRO} from "./Select";

import { TimelineMax } from "gsap";

class Landing extends Component {
    componentDidMount() {
        let tl = new TimelineMax({delay: 0.5});
        tl.set('.landing-main', {opacity: 1}).from('.landing-logo', { scale: 0.9, opacity: 0})
            .from('.landing-item-title', {yPercent :-10, opacity: 0})
            .from('.landing-item-subtitle', {xPercent: -10, opacity: 0})
            .from('.landing-button', {opacity : 0})
            .from('.landing-div', {scale: 0.9, opacity: 0});
    }

    render() {
        return (
            <div className={'landing'}>
                <div className="landing-overlay">
                    <div className="landing-main">
                        <img className={'landing-logo'} src="/assets/textures/logo.png" alt=""/>
                        <div className="landing-warning">Works best in Chrome.</div>
                        <div className="landing-select">
                                <div className="landing-item">
                                    <div className="landing-item-title">
                                        basic.
                                    </div>
                                    <div className="landing-item-subtitle">
                                        My computer can handle an interesting spreadsheet.
                                    </div>
                                    <button onClick={() => this.props.onClick(BASIC)} className="landing-button">select</button>
                                </div>
                            <div className="landing-div">
                                or
                            </div>
                            <div className="landing-select">
                                <div className="landing-item">
                                    <div className="landing-item-title">
                                        pro.
                                    </div>
                                    <div className="landing-item-subtitle">
                                        I tell other people I'm working — but really I'm playing Call of Duty.
                                    </div>
                                    <button onClick={() => this.props.onClick(PRO)} className="landing-button">select</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="landing-video-wrapper">
                    <video className={'landing-video'} src='/assets/videos/select_bg.mp4' muted autoPlay loop />

                </div>

            </div>
        );
    }
}

export default Landing;