import * as THREE from 'three';


export class LinearSpline {
    constructor(lerp) {
      this._points = [];
      this._lerp = lerp;
    }
  
    AddPoint(t, d) {
      this._points.push([t, d]);
    }
  
    Get(t) {
      let p1 = 0;
  
      for (let i = 0; i < this._points.length; i++) {
        if (this._points[i][0] >= t) {
          break;
        }
        p1 = i;
      }
  
      const p2 = Math.min(this._points.length - 1, p1 + 1);
  
      if (p1 == p2) {
        return this._points[p1][1];
      }
  
      return this._lerp(
          (t - this._points[p1][0]) / (
              this._points[p2][0] - this._points[p1][0]),
          this._points[p1][1], this._points[p2][1]);
    }
  }



const _VS = `
uniform float pointMultiplier;

attribute float size;
attribute vec4 colour;

varying vec4 vColour;

void main() {
  vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
  
  gl_Position = projectionMatrix * mvPosition;
  gl_PointSize = size *  pointMultiplier / gl_Position.w;
    vColour = colour;
}`;

const _FS = `
uniform sampler2D diffuseTexture;

varying vec4 vColour;

void main() {
  gl_FragColor = texture2D(diffuseTexture, gl_PointCoord) * vColour ;
}`;

export class ParticleSystem {
    constructor(scene, camera, img, rate = 100) {
        this.scene = scene;
        this.camera = camera;
        this.particles = [];
        this.points  = [];
        console.log('Particles inited');
        this.img = img;
        this.rate = rate;
        this.init();
        this.alphaSpline = this.generateAlphaSpline();
        this.sizeSpline = this.generateSizeaSpline();
    }

    generateSizeaSpline() {
        let s = new LinearSpline((t,a,b) => {
            return a+ t * (b -a);
        });

        s.AddPoint(0.0, 0.0);
        s.AddPoint(0.1, 0.2);
        s.AddPoint(0.5, 0.5);
        s.AddPoint(1.0,1.0);
        return s;
    
    }

    generateAlphaSpline() {
        let s = new LinearSpline((t,a,b) => {
            return a+ t * (b -a);
        });

        s.AddPoint(0.0, 0.0);
        s.AddPoint(0.1, 1.0);
        s.AddPoint(0.5, 0.5);
        s.AddPoint(1.0, 0.0);
        return s;
    
    }

    init() {
        
        const uniforms =  {
            diffuseTexture : {
                value : new THREE.TextureLoader().load(`/assets/textures/${this.img}`)
            },
            pointMultiplier : {
                value: window.innerHeight / (2.0 * Math.tan(0.5 * 60.0 * Math.PI / 180.0))
            }
        }


        
        this.material = new THREE.ShaderMaterial({
            uniforms : uniforms,
            vertexShader : _VS,
            fragmentShader : _FS,
            blending : THREE.AdditiveBlending,
            depthTest : true,
            depthWrite : false,
            transparent : true,
            vertexColors : true
        });

        this.geo = new THREE.BufferGeometry();
        // this.geo.boundingBox = null;
        this.geo.setAttribute('position',new THREE.Float32BufferAttribute([], 3));
        this.geo.setAttribute('size',new THREE.Float32BufferAttribute([], 1));
        this.geo.setAttribute('colour',new THREE.Float32BufferAttribute([], 4));
        this.geo.setAttribute('particleID', new THREE.Float32BufferAttribute([], 1));
        // this.geo.setAttribute('angle',new THREE.Float32BufferAttribute([], 1));

        this.points = new THREE.Points(this.geo, this.material);
        this.scene.add( this.points );
        this.addParticles();
        this.updateGeo();
        this.updateParticles();

        window.addEventListener('keydown', e => {
            this.addDeformation();
        });

    }


    addDeformation(indicies = []) {

        indicies.forEach(index => {
            console.log(index);
            this.particles.forEach(p => {

                
                if(p.particleID === index) {
                 
                    p.deform = {x : 4, z: 4};
                }
            })
        })
    }


    updateParticles(timeElapsed) {

        if(!timeElapsed) {
            return;
        }

        for (let p of this.particles) {
            p.life =  (p.life - timeElapsed);
        }
            this.particles  = this.particles.filter(p => {
                    return p.life > 0.0;
        });

        for(let p of this.particles) {
            const t = 1.0 - p.life / p.maxLife;
            p.alpha = this.alphaSpline.Get(t);
            p.currentSize = p.size * this.sizeSpline.Get(t);
            let currentV = p.velocity.clone();

        

            currentV.x = p.deform.x;
            currentV.z = p.deform.z;
            p.position.add(currentV.multiplyScalar(timeElapsed));

            if(p.deform.x > 0) {
                p.deform.x  = (p.deform.x - timeElapsed);
            }

            if(p.deform.z > 0) {
                p.deform.z  = (p.deform.z - timeElapsed);
            }
            

            // const drag = p.velocity.clone();
            // drag.multiplyScalar(timeElapsed * 0.1);
            // drag.x = Math.sign(p.velocity.x) * Math.min(Math.abs(drag.x), Math.abs(p.velocity.x));
            // drag.y = Math.sign(p.velocity.y) * Math.min(Math.abs(drag.y), Math.abs(p.velocity.y));
            // drag.z = Math.sign(p.velocity.z) * Math.min(Math.abs(drag.z), Math.abs(p.velocity.z));
            // p.velocity.sub(drag);
        }


        this.particles.sort((a,b) => {
            const d1 = this.camera.position.distanceTo(a.position);
            const d2 = this.camera.position.distanceTo(b.position);

            if (d1 > d2) {
                return -1;
              }
        
              if (d1 < d2) {
                return 1;
              }
        
              return 0;
        });

    
        
    }

    random(min, max) {
        return Math.floor(Math.random()*(max-min+1)+min);
       }
     

    addParticles(timeElapsed) {
        let rate = this.rate;
        if (!this.gdfsghk) {
            this.gdfsghk = 0.0;
          }
          this.gdfsghk += timeElapsed;
          const n = Math.floor(this.gdfsghk * rate);
          this.gdfsghk -= n / rate;

        for(let i = 0; i < n; i++) {
            const size = Math.random() * 10.0;
            const life = (Math.random() * 0.75 + 0.25) * 10.0;
            this.particles.push({
                position : new THREE.Vector3(
                    this.random(2, -5),
                    this.random(5, 2),
                    this.random(25,15)
                ),
                size: Math.random() * 10.0,
                    
                    // size: 200
          color: new THREE.Color(0xFFFFFF),
          alpha : (Math.random() * 1 - 0.1 ) * 1,
          life : life,
          maxLife : life,
          velocity: new THREE.Vector3(0, (size * 1.5), 0),
          particleID : 1,
          deform : {
              x : 0,
              z : 0
          }
                
         })
        }
    }

    updateGeo() {
            const positions  =[];
            const sizes = [];
            const colors = [];
            const IDS = [];

            for( let p of this.particles) {
                positions.push(p.position.x, p.position.y, p.position.z);
                sizes.push(p.currentSize);
                colors.push(p.color.r, p.color.g, p.color.b, p.alpha);
                IDS.push(p.particleID);
            }

            this.geo.setAttribute(
                'position', new THREE.Float32BufferAttribute(positions, 3)
            );

            this.geo.setAttribute(
                'size', new THREE.Float32BufferAttribute(sizes, 1)
            );

            this.geo.setAttribute(
                'colour', new THREE.Float32BufferAttribute(colors, 4)
            );
            this.geo.setAttribute(
                'particleID', new THREE.Float32BufferAttribute(IDS, 1)
            );
            this.geo.attributes.position.needsUpdate = true;
            this.geo.attributes.size.needsUpdate = true;
            this.geo.attributes.colour.needsUpdate = true;
            this.geo.attributes.particleID.needsUpdate = true;

            this.geo.computeBoundingSphere();
    }

    step(timeElapsed) {
        this.addParticles(timeElapsed);
        this.updateParticles(timeElapsed);
        this.updateGeo();
    }

}